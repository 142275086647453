
import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import "../styles/navbar.css";

const MyNavbar = () => {
  return (
    <Navbar  variant="dark" expand="lg" className="p-3 mobile-p-3 navigationbar">
      <Container >
        <Navbar.Brand href="/">
          <img 
            src="/Assets/ivorylogo1.png"
            className="d-inline-block align-top ivorylogo"
            alt="Logo"
          />
          
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto menu">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About Us</Nav.Link>
            <Nav.Link href="/service">Services</Nav.Link>
            <Nav.Link href="/contact">Contact Us</Nav.Link>
          </Nav>
          <Button href='/contact' variant="outline-light" className="ml-3 ivorybutton">
            Get in Touch
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;

