import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import "../styles/home.css";
import WorkProcess from './WorkProcess';
import MajorSector from './MajorSector';
import Achievement from './Achievement';
import Introduction from './Introduction';
import OurService from './OurService';
const Home = () => {


    const servicesData = [
        {
          title: "Debt Recovery",
          description: "Efficient and reliable debt recovery services to help you manage outstanding debts.",
          link: "/services/debt-recovery"
        },
        {
          title: "Enforcement",
          description: "Professional enforcement services to ensure compliance and legal actions.",
          link: "/services/enforcement"
        },
        {
          title: "Skip Tracing",
          description: "Locate individuals quickly and accurately with our skip tracing services.",
          link: "/services/skip-tracing"
        }
      ];

  return (
    <div className='container'>
    {/* <ScrollButtons/> */}
  <section id='headingcarouse'>
    <Carousel fade>
      <Carousel.Item>
        <div className="carousel-img-container">
          <img
            className="d-block w-100 imgcorousel"
            src="../Assets/carouselimg1.png"
            alt="First slide"
          />
          <div className="carousel-overlay"></div>
        </div>
        {/* <Carousel.Caption>
       <h4 className="carousel-text-animation text-center">"Ultimate Resolutions for Debt Recovery & Enforcement"</h4>
       <a href="/service"><button href='/about' className="carousel-btn" >Know More</button></a>
        </Carousel.Caption> */}
      </Carousel.Item>

      <Carousel.Item>
        <div className="carousel-img-container">
          <img
            className="d-block w-100 imgcorousel"
            src="../Assets/carouselimg2.jpeg"
            alt="Second slide"
          />
          <div className="carousel-overlay"></div>
        </div>
     
      </Carousel.Item>
    </Carousel>
  </section>

   <section>
   <Introduction/>
   </section>

  
       <section>
        <OurService/>
       </section>

       <section>
       <WorkProcess/>
       </section>

       <section>
       <MajorSector/>
       </section>

       <section>
        <Achievement/>
       </section>

</div>
  )
}

export default Home
