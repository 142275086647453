import React from 'react';
import "../styles/whatsapp.css";

const Whatsapp = () => {
  return (
    <div className="whatsapp-container">
      <a href="https://wa.link/bk616a" target="_blank" rel="noopener noreferrer">
        <img src="/Assets/whatsapp.png" alt="WhatsApp" className="whatsapp" />
        <span className="whatsapp-text">Let's chat</span>
      </a>
    </div>
  );
}

export default Whatsapp;
