import React from 'react'
import '../styles/major.css'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
const OurService = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  

  return (
      <div className='' >
       <div className="container" style={{width:'100%',overflow:'hidden'}}>
       {/* <div className='majorsector' style={{width:'100%',overflow:'hidden'}}> <h1  > Our Service</h1></div> */}
       <div className='ourservice'><h1 className='animatedText ' data-aos="zoom-in">Our Services</h1></div>
      <Slider {...settings}>
      <div>
      <img src="/Assets/dept.jpg" alt="" className='serviceimg'/>
      <div className='imgcard'>
          <h4>Debt Recovery</h4>
          <a href="/service"><button className="custom-button" >Know More</button></a>
          </div>
      </div>
      <div>
      <img src="/Assets/enforcement.jpg" alt="" className='serviceimg'/>
      <div className='imgcard'>
          <h4>Enforcement</h4>
          <a href="/service"><button className="custom-button" >Know More</button></a>
          </div>
      </div>
      <div>
      <img src="/Assets/skip.jpg" alt="" className='serviceimg'/>
      <div className='imgcard'>
          <h4>Skip Tracing</h4>
          <a href="/service"><button className="custom-button" >Know More</button></a>
          </div>
      </div>
      <div>
      <img src="/Assets/legal.jpg" alt="" className='serviceimg'/>
      <div className='imgcard'>
          <h4>Legal Assistance</h4>
          <a href="/service"><button className="custom-button" >Know More</button></a>
          </div>
      </div>
      <div>
      <img src="/Assets/additional.jpg" alt="" className='serviceimg'/>
      <div className='imgcard'>
          <h4>Additional Services</h4>
          <a href="/service"><button className="custom-button" >Know More</button></a>
          </div>
      </div>
    
     
    </Slider>
      </div>
      </div>
  )
}

export default OurService
